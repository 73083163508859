import React, { useState, useEffect } from 'react';
import '../../styles/login_style/Login.scss';
import liftcoLogo from '../../assets/images/liftco_logo.svg';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

import api from '../../utils/api';

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [groups, setGroups] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      console.log('Submitting login form');
      const response = await api.post('/api/token/', { username, password });
      if (response.status === 200) {
        const data = response.data;
        //console.log('Login successful', data);
        console.log('Login successful');
        
        // Store tokens and decode user_id
        localStorage.setItem('access', data.access);
        localStorage.setItem('refresh', data.refresh);
        const userId = jwtDecode(data.access).user_id;
        localStorage.setItem("userId", userId);

        // Fetch user details
        const userResponse = await api.get(`/users/${userId}/`);
        const userData = userResponse.data;

        // Store user details
        localStorage.setItem('userName', userData.first_name + " " + userData.last_name);
        
        // Get group name by group id
        const groupResponse = await api.get('/groups/');
        const groups = groupResponse.data;
        const userGroup = groups.find(group => group.id === userData.groups[0]);
        localStorage.setItem('userRole', userGroup ? userGroup.name : '');
        localStorage.setItem('userGroupId', userGroup ? userGroup.id : '');
        const groupPermissionsResponse = await api.get(`/groups/${userGroup.id}/`);
        const permissions = groupPermissionsResponse.data.permissions;
        
        // Get unique content types and store them
        const uniqueContentTypes = [...new Set(permissions.map(perm => perm.content_type))];
        uniqueContentTypes.push(15);
        localStorage.setItem('userPermissions', JSON.stringify(uniqueContentTypes));
        console.log("User permissions:", localStorage.getItem('userPermissions'));
        navigate('/home');
        
      } else {
        setError('Login failed. Please try again.');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('An error occurred. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <img src={liftcoLogo} alt="LIFTCO Logo" className="liftco-logo" />
        <form onSubmit={handleSubmit}>
          <div className="login-input-group">
            <div className="input-group">
              <label htmlFor="username">Username</label>
              <input 
                type="text" 
                id="username" 
                name="username" 
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="input-group">
              <label htmlFor="password">Password</label>
              <input 
                type="password" 
                id="password" 
                name="password" 
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
          </div>
          {error && <div className="error-message">{error}</div>}
          <button type="submit" className="login-button" disabled={isLoading}>
            {isLoading ? 'Logging in...' : 'Login'}
          </button>
          {isLoading && <div className="loader"></div>}
        </form>
        <Link to="/reset-account" className="forgot-password">Forgot my password</Link>
      </div>
    </div>
  );
}

export default Login;
