import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../styles/components/Sidebar.scss';
import icon0 from '../assets/images/icon_home.svg';
import icon1 from '../assets/images/icon_user_module.svg';
import icon2 from '../assets/images/icon_clients_module.svg';
import icon3 from '../assets/images/icon_machinery_module.svg';
import icon4 from '../assets/images/icon_job_module.svg';
import icon5 from '../assets/images/icon_inventory_module.svg';
import icon6 from '../assets/images/icon_providers_module.svg';
import icon7 from '../assets/images/icon_purchase_order_module.svg';

const MODULE_PERMISSIONS = {
  user: 6,      // Users content type
  clients: 9,   // Clients content type
  machinery: 10, // Machinery content type
  job: 15,      // Jobs content type
  inventory: 8, // Inventory content type
  providers: 7, // Providers content type
  'purchase-orders': 14 // Purchase Orders content type
};

const Sidebar = () => {
  const location = useLocation();
  const userPermissions = JSON.parse(localStorage.getItem('userPermissions') || '[]');

  const hasPermission = (moduleKey) => {
    return moduleKey === 'home' || userPermissions.includes(MODULE_PERMISSIONS[moduleKey]);
  };

  const modules = [
    { icon: icon0, name: 'Home', path: '/home', key: 'home' },
    { icon: icon1, name: 'User Module', path: '/user', key: 'user' },
    { icon: icon2, name: 'Clients Module', path: '/clients', key: 'clients' },
    { icon: icon3, name: 'Machinery Module', path: '/machinery', key: 'machinery' },
    { icon: icon4, name: 'Job Module', path: '/job', key: 'job' },
    { icon: icon5, name: 'Inventory', path: '/inventory', key: 'inventory' },
    { icon: icon6, name: 'Providers', path: '/providers', key: 'providers' },
    { icon: icon7, name: 'Purchase Orders', path: '/purchase-orders', key: 'purchase-orders' }
  ];

  return (
    <div className="sidebar">
      {modules.map((module, index) => (
        hasPermission(module.key) && (
          <Link 
            key={index} 
            to={module.path} 
            className={`sidebar-icon ${location.pathname === module.path ? 'active' : ''}`}
          >
            <img src={module.icon} alt={module.name} />
            <span>{module.name}</span>
          </Link>
        )
      ))}
    </div>
  );
};

export default Sidebar;
