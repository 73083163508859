import React, { useState, useEffect } from 'react';
import '../../styles/clients_module_style/ClientsModule.scss';
import Sidebar from '../../components/Sidebar';
import api from '../../utils/api';
import icon1 from '../../assets/images/icon_delete.svg';
import icon2 from '../../assets/images/icon_edit.svg';
import icon3 from '../../assets/images/icon_add.svg';
import { 
  TextField, 
  IconButton, 
  InputAdornment, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  TablePagination, 
  Paper,
  Box
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const ClientsModule = () => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editingClient, setEditingClient] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingClient, setDeletingClient] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredClients, setFilteredClients] = useState([]);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/clients/');
        setClients(response.data);
        setFilteredClients(response.data);
        setLoading(false);
      } catch (err) {
        setError('Error fetching data');
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleRowClick = (index) => {
    setSelectedRow(index === selectedRow ? null : index);
  };

  const handleEdit = async () => {
    if (selectedRow !== null) {
      setEditingClient(filteredClients[selectedRow]);
      setIsEditing(true);
    }
  };

  const handleEditChange = (e) => {
    setEditingClient({ ...editingClient, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    try {
      if (isCreating) {
        await api.post('/clients/', editingClient);
        setConfirmationMessage('Client created successfully');
      } else {
        await api.put(`/clients/${editingClient.id}/`, editingClient);
        setConfirmationMessage('Client updated successfully');
      }
      const response = await api.get('/clients/');
      setClients(response.data);
      setFilteredClients(response.data);
      setIsEditing(false);
      setIsCreating(false);
      setEditingClient(null);
      setShowConfirmation(true);
    } catch (err) {
      setError('Error saving data');
    }
  };

  const handleDelete = async () => {
    if (selectedRow !== null) {
      setDeletingClient(filteredClients[selectedRow]);
      setIsDeleting(true);
    }
  };

  const confirmDelete = async () => {
    try {
      console.log(deletingClient);
      await api.post(`/clients/${deletingClient.id}/deactivate/`);
      const response = await api.get('/clients/');
      setClients(response.data);
      setFilteredClients(response.data);
      setIsDeleting(false);
      setDeletingClient(null);
      setSelectedRow(null);
      setConfirmationMessage('Client deleted successfully');
      setShowConfirmation(true);
    } catch (err) {
      setError('Error deleting data');
    }
  };

  const handleCreate = () => {
    setEditingClient({
      client_name: '',
      to_bill_location: '',
      to_ship_location: '',
      email: '',
      cost: ''
    });
    setIsCreating(true);
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    setPage(0);
    const filtered = clients.filter(client =>
      client.client_name.toLowerCase().includes(searchTerm) ||
      client.email.toLowerCase().includes(searchTerm) ||
      client.to_bill_location.toLowerCase().includes(searchTerm) ||
      client.to_ship_location.toLowerCase().includes(searchTerm)
    );
    setFilteredClients(filtered);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="clients-module-container">
      <Sidebar userType="admin" />
      <div className="clients-module">
        <h1>Clients Module</h1>
          <Box className="action-container">
          <TextField
            fullWidth
            placeholder="Search..."
            variant="outlined"
            InputProps={{
              endAdornment: (
                <IconButton className="search-button">
                  <SearchIcon />
                </IconButton>
              ),
            }}
            className="search-input" onChange={handleSearch}
          />
          
          <IconButton className="delete-button" onClick={handleDelete}>
            <img src={icon1} alt="Delete" />
          </IconButton>

          <IconButton className="edit-button" onClick={handleEdit}>
            <img src={icon2} alt="Edit" />
          </IconButton>
          
          <IconButton className="add-button" onClick={handleCreate}>
            <img src={icon3} alt="Add" />
            </IconButton>
          </Box>
        {loading ? (
          <p>Loading clients...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <TableContainer component={Paper}>
            <Table className="clients-table">
              <TableHead>
                <TableRow>
                  <TableCell>Client Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Billing Location</TableCell>
                  <TableCell>Shipping Location</TableCell>
                  <TableCell>Cost ($/hr)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredClients
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((client, index) => (
                    <TableRow 
                      key={client.id} 
                      onClick={() => handleRowClick(index)}
                      className={selectedRow === index ? 'selected' : ''}
                    >
                      <TableCell>{client.client_name}</TableCell>
                      <TableCell>{client.email}</TableCell>
                      <TableCell>{client.to_bill_location}</TableCell>
                      <TableCell>{client.to_ship_location}</TableCell>
                      <TableCell>${parseFloat(client.cost).toFixed(2)}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredClients.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
      </div>
      {(isEditing || isCreating) && (
        <div className="edit-popup">
          <div className="edit-popup-content">
            <h2>{isCreating ? 'Create Client' : 'Edit Client'}</h2>
            {error && <div className="error-message">{error}</div>}
            <div className="edit-form">
              <div className="form-group">
                <label>Client Name</label>
                <input
                  type="text"
                  name="client_name"
                  value={editingClient.client_name}
                  onChange={handleEditChange}
                />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  value={editingClient.email}
                  onChange={handleEditChange}
                />
              </div>
              <div className="form-group">
                <label>Billing Location</label>
                <input
                  type="text"
                  name="to_bill_location"
                  value={editingClient.to_bill_location}
                  onChange={handleEditChange}
                />
              </div>
              <div className="form-group">
                <label>Shipping Location</label>
                <input
                  type="text"
                  name="to_ship_location"
                  value={editingClient.to_ship_location}
                  onChange={handleEditChange}
                />
              </div>
              <div className="form-group">
                <label>Cost ($/hr)</label>
                <div className="cost-input-wrapper">
                  <span className="dollar-sign" style={{ paddingBottom: '3px' }}>$</span>
                  <input
                    className="cost-input"
                    type="number"
                    step="0.01"
                    name="cost"
                    value={editingClient.cost}
                    onChange={handleEditChange}
                    style={{ paddingLeft: '20px' }}
                  />
                </div>
              </div>
            </div>
            <div className="edit-buttons">
              <button className="cancel-btn" onClick={() => {
                setIsEditing(false);
                setIsCreating(false);
                setEditingClient(null);
                setError('');
              }}>Cancel</button>
              <button className="save-btn" onClick={handleSave}>Save</button>
            </div>
          </div>
        </div>
      )}
      {isDeleting && (
        <div className="delete-alert">
          <div className="delete-alert-content">
            <h2>⚠ Alert</h2>
            <p>Do you want to delete {deletingClient.client_name}?</p>
            <div className="delete-buttons">
              <button className="cancel-btn" onClick={() => setIsDeleting(false)}>Cancel</button>
              <button className="ok-btn" onClick={confirmDelete}>Ok</button>
            </div>
          </div>
        </div>
      )}
      {showConfirmation && (
        <div className="confirmation-popup">
          <div className="confirmation-popup-content">
            <h2>✓ Done</h2>
            <p>{confirmationMessage}</p>
            <button className="ok-btn" onClick={() => setShowConfirmation(false)}>Ok</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientsModule;
