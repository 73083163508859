import React, { useState, useEffect } from 'react';
import api from '../../utils/api';
import { 
  TextField, 
  IconButton, 
  InputAdornment, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  TablePagination, 
  Paper,
  Box
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import icon1 from '../../assets/images/icon_delete.svg';
import icon2 from '../../assets/images/icon_edit.svg';
import icon3 from '../../assets/images/icon_add.svg';
import '../../styles/purchase_order_style/PurchaseOrderItems.scss';

const PurchaseOrderItems = ({ orderId, onClose }) => {
  const [items, setItems] = useState([]);
  const [itemDetails, setItemDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isEditing, setIsEditing] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingItem, setDeletingItem] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [inventoryItems, setInventoryItems] = useState([]);
  const [itemSearchTerm, setItemSearchTerm] = useState('');
  const [filteredInventoryItems, setFilteredInventoryItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [itemsResponse, inventoryResponse] = await Promise.all([
          api.get(`/purchase-orders/${orderId}/items`),
          api.get('/items/')
        ]);
        setItems(itemsResponse.data);
        setFilteredItems(itemsResponse.data);
        setInventoryItems(inventoryResponse.data);
        
        await fetchItemDetails(itemsResponse.data);
        setLoading(false);
      } catch (err) {
        setError('Error fetching data');
        setLoading(false);
      }
    };
    fetchData();
  }, [orderId]);

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    setPage(0);
    const filtered = items.filter(item => {
      const itemDetail = itemDetails[item.id_item] || {};
      console.log(itemDetail);
      return (
        itemDetail.item_name?.toLowerCase().includes(searchTerm) ||
        item.quantity.toString().includes(searchTerm) ||
        item.set_price.toString().includes(searchTerm)
      );
    });
    setFilteredItems(filtered);
  };

  const handleRowClick = (index) => {
    setSelectedRow(index === selectedRow ? null : index);
  };

  const handleEdit = () => {
    if (selectedRow !== null) {
      const itemToEdit = filteredItems[selectedRow];
      const itemDetail = itemDetails[itemToEdit.id_item] || {};
      
      setEditingItem({
        ...itemToEdit,
        id_purchase_order: orderId,
        id_item: itemToEdit.id_item.toString(),
        part_id: itemDetail.part_id,
        item_name: itemDetail.item_name
      });
      setIsEditing(true);
    }
  };

  const handleCreate = () => {
    setEditingItem({
      id_purchase_order: orderId,
      id_item: '',
      quantity: 1,
      set_price: '0.00'
    });
    console.log(editingItem);
    setIsCreating(true);
  };

  const handleDelete = () => {
    if (selectedRow !== null) {
      const itemToDelete = filteredItems[selectedRow];
      setDeletingItem(itemToDelete);
      setIsDeleting(true);
    }
  };

  const handleEditChange = (e) => {
    setEditingItem({ ...editingItem, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    try {
      const itemData = {
        ...editingItem,
        id_purchase_order: orderId
      };

      if (isCreating) {
        await api.post(`/purchase-order-items/`, itemData);
        setConfirmationMessage('Item added successfully');
      } else {
        await api.put(`/purchase-order-items/${editingItem.id}/`, itemData);
        setConfirmationMessage('Item updated successfully');
      }
      const response = await api.get(`/purchase-orders/${orderId}/items`);
      setItems(response.data);
      setFilteredItems(response.data);
      await fetchItemDetails(response.data);
      setIsEditing(false);
      setIsCreating(false);
      setEditingItem(null);
      setShowConfirmation(true);
    } catch (err) {
      setError('Error saving data');
    }
  };

  const confirmDelete = async () => {
    try {
      console.log(deletingItem);
      await api.post(`/purchase-order-items/${deletingItem.id}/deactivate/`);
      const response = await api.get(`/purchase-orders/${orderId}/items`);
      setItems(response.data);
      setFilteredItems(response.data);
      setIsDeleting(false);
      setDeletingItem(null);
      setSelectedRow(null);
      setConfirmationMessage('Item deleted successfully');
      setShowConfirmation(true);
    } catch (err) {
      setError('Error deleting data');
    }
  };

  const fetchItemDetails = async (itemsData) => {
    try {
      const details = await Promise.all(
        itemsData.map(item => api.get(`/items/${item.id_item}/`))
      );
      const itemDetailsMap = {};
      details.forEach((detail, index) => {
        itemDetailsMap[itemsData[index].id_item] = detail.data;
      });
      setItemDetails(itemDetailsMap);
    } catch (err) {
      setError('Error fetching item details');
    }
  };

  return (
    <div className="purchase-order-items-popup">
      <div className="purchase-order-items-content">
        <div className="header">
          <h2>Purchase Order Items - Order #{orderId}</h2>
          <button className="close-btn" onClick={onClose}>×</button>
        </div>
        
        <Box className="action-container">
          <TextField
            fullWidth
            placeholder="Search..."
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton className="search-button">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            className="search-input"
            onChange={handleSearch}
          />
          <IconButton className="delete-button" onClick={handleDelete}>
            <img src={icon1} alt="Delete" />
          </IconButton>
          <IconButton className="edit-button" onClick={handleEdit}>
            <img src={icon2} alt="Edit" />
          </IconButton>
          <IconButton className="add-button" onClick={handleCreate}>
            <img src={icon3} alt="Add" />
          </IconButton>
        </Box>

        {loading ? (
          <p>Loading items...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Item ID</TableCell>
                  <TableCell>Item Name</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredItems
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => {
                    const itemDetail = itemDetails[item.id_item] || {};
                    return (
                      <TableRow 
                        key={item.id}
                        onClick={() => handleRowClick(index)}
                        className={selectedRow === index ? 'selected' : ''}
                      >
                        <TableCell>{itemDetail.part_id || 'N/A'}</TableCell>
                        <TableCell>{itemDetail.item_name || 'Unknown'}</TableCell>
                        <TableCell>{item.quantity}</TableCell>
                        <TableCell>${parseFloat(item.set_price).toFixed(2)}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredItems.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(e, newPage) => setPage(newPage)}
              onRowsPerPageChange={(e) => {
                setRowsPerPage(parseInt(e.target.value, 10));
                setPage(0);
              }}
            />
          </TableContainer>
        )}
      </div>
      {(isEditing || isCreating) && (
        <div className="edit-popup">
          <div className="edit-popup-content">
            <h2>{isCreating ? 'Add Item' : 'Edit Item'}</h2>
            {error && <div className="error-message">{error}</div>}
            <div className="edit-form">
              <div className="form-group">
                <label>Search Item</label>
                <TextField
                  fullWidth
                  placeholder="Search items..."
                  variant="outlined"
                  value={itemSearchTerm}
                  onChange={(e) => {
                    const term = e.target.value.toLowerCase();
                    setItemSearchTerm(term);
                    if (term) {
                      const filtered = inventoryItems.filter(item =>
                        `${item.part_id} - ${item.item_name}`.toLowerCase().includes(term)
                      );
                      setFilteredInventoryItems(filtered);
                    } else {
                      setFilteredInventoryItems([]);
                    }
                  }}
                />
                {itemSearchTerm && (
                  <div className="search-results">
                    {filteredInventoryItems.map(item => (
                      <div 
                        key={item.id} 
                        className="search-result-item"
                        onClick={() => {
                          setEditingItem({
                            ...editingItem,
                            id_item: item.id.toString(),
                            set_price: item.price,
                            part_id: item.part_id,
                            item_name: item.item_name
                          });
                          setItemSearchTerm('');
                          setFilteredInventoryItems([]);
                        }}
                      >
                        {`${item.part_id} - ${item.item_name}`}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label>Item ID</label>
                <input
                  type="text"
                  value={editingItem.part_id || ''}
                  disabled
                />
              </div>
              <div className="form-group">
                <label>Item Name</label>
                <input
                  type="text"
                  value={editingItem.item_name || ''}
                  disabled
                />
              </div>
              <div className="form-group">
                <label>Quantity</label>
                <input
                  type="number"
                  name="quantity"
                  value={editingItem.quantity}
                  onChange={handleEditChange}
                  min="1"
                />
              </div>
              <div className="form-group">
                <label>Price</label>
                <input
                  type="number"
                  name="set_price"
                  value={editingItem.set_price}
                  onChange={handleEditChange}
                  step="0.01"
                  min="0"
                  disabled
                />
              </div>
            </div>
            <div className="edit-buttons">
              <button className="cancel-btn" onClick={() => {
                setIsEditing(false);
                setIsCreating(false);
                setEditingItem(null);
                setError('');
              }}>Cancel</button>
              <button className="save-btn" onClick={handleSave}>Save</button>
            </div>
          </div>
        </div>
      )}

      {isDeleting && (
        <div className="delete-alert">
          <div className="delete-alert-content">
            <h2>⚠ Alert</h2>
            <p>Do you want to delete this item?</p>
            <div className="delete-buttons">
              <button className="cancel-btn" onClick={() => setIsDeleting(false)}>Cancel</button>
              <button className="ok-btn" onClick={confirmDelete}>Ok</button>
            </div>
          </div>
        </div>
      )}

      {showConfirmation && (
        <div className="confirmation-popup">
          <div className="confirmation-popup-content">
            <h2>✓ Done</h2>
            <p>{confirmationMessage}</p>
            <button className="ok-btn" onClick={() => setShowConfirmation(false)}>Ok</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PurchaseOrderItems; 