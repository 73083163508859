import React, { useState, useEffect } from 'react';
import '../../styles/machinery_module_style/MachineryModule.scss';
import Sidebar from '../../components/Sidebar';
import api from '../../utils/api';
import icon1 from '../../assets/images/icon_delete.svg';
import icon2 from '../../assets/images/icon_edit.svg';
import icon3 from '../../assets/images/icon_add.svg';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    TablePagination, 
    Paper,
    Box
  } from '@mui/material';

const MachineryModule = () => {
    const [machineries, setMachineries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editingMachinery, setEditingMachinery] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [deletingMachinery, setDeletingMachinery] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredMachineries, setFilteredMachineries] = useState([]);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [clients, setClients] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
  
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [machineriesResponse, clientsResponse] = await Promise.all([
                    api.get('/machinery/'),
                    api.get('/clients/')
                ]);
                setMachineries(machineriesResponse.data);
                setFilteredMachineries(machineriesResponse.data);
                setClients(clientsResponse.data);
                setLoading(false);
            } catch (err) {
                setError('Error fetching data');
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const handleRowClick = (index) => {
        setSelectedRow(index === selectedRow ? null : index);
    };

    const handleEdit = async () => {
        if (selectedRow !== null) {
            setEditingMachinery(filteredMachineries[selectedRow]);
            setIsEditing(true);
        }
    };

    const handleEditChange = (e) => {
        setEditingMachinery({ ...editingMachinery, [e.target.name]: e.target.value });
    };

    const handleSave = async () => {
        try {
            if (isCreating) {
                await api.post('/machinery/', editingMachinery);
                setConfirmationMessage('Machinery created successfully');
            } else {
                await api.put(`/machinery/${editingMachinery.id}/`, editingMachinery);
                setConfirmationMessage('Machinery updated successfully');
            }
            const response = await api.get('/machinery/');
            setMachineries(response.data);
            setFilteredMachineries(response.data);
            setIsEditing(false);
            setIsCreating(false);
            setEditingMachinery(null);
            setShowConfirmation(true);
        } catch (err) {
            setError('Error saving data');
        }
    };

    const handleDelete = async () => {
        if (selectedRow !== null) {
            setDeletingMachinery(filteredMachineries[selectedRow]);
            setIsDeleting(true);
        }
    };

    const confirmDelete = async () => {
        try {
            await api.post(`/machinery/${deletingMachinery.id}/deactivate/`);
            const response = await api.get('/machinery/');
            setMachineries(response.data);
            setFilteredMachineries(response.data);
            setIsDeleting(false);
            setDeletingMachinery(null);
            setSelectedRow(null);
            setConfirmationMessage('Machinery deleted successfully');
            setShowConfirmation(true);
        } catch (err) {
            setError('Error deleting data');
        }
    };

    const handleCreate = () => {
        setEditingMachinery({
            unit: '',
            model: '',
            serial_number: '',
            location: '',
            id_client: ''
        });
        setIsCreating(true);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        setSearchTerm(searchTerm);
        const filtered = machineries.filter(machinery =>
            machinery.unit.toLowerCase().includes(searchTerm) ||
            machinery.model.toLowerCase().includes(searchTerm) ||
            machinery.serial_number.toLowerCase().includes(searchTerm) ||
            machinery.location.toLowerCase().includes(searchTerm)
        );
        setFilteredMachineries(filtered);
    };

    const getClientName = (id) => {
        const client = clients.find(c => c.id === id);
        return client ? client.client_name : 'Unknown';
    };

    return (
        <div className="machinery-module-container">
            <Sidebar userType="admin" />
            <div className="machinery-module">
                <h1>Machinery Module</h1>
                <div className="action-container">
                    <TextField
                        placeholder="Search..."
                        variant="outlined"
                        value={searchTerm}
                        onChange={handleSearch}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton className="search-button">
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <IconButton className="delete-button" onClick={handleDelete}>
                        <img src={icon1} alt="Delete" />
                    </IconButton>
                    <IconButton className="edit-button" onClick={handleEdit}>
                        <img src={icon2} alt="Edit" />
                    </IconButton>
                    <IconButton className="create-button" onClick={handleCreate}>
                        <img src={icon3} alt="Add" />
                    </IconButton>
                </div>
                {loading ? (
                    <p>Loading machineries...</p>
                ) : error ? (
                    <p>{error}</p>
                ) : (
                    <TableContainer component={Paper}>
                        <Table className="machinery-table">
                        <TableHead>
                            <TableRow>
                            <TableCell>Unit</TableCell>
                            <TableCell>Model</TableCell>
                            <TableCell>Serial Number</TableCell>
                            <TableCell>Location</TableCell>
                            <TableCell>Client</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredMachineries
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((machinery, index) => (
                                <TableRow 
                                key={machinery.id} 
                                onClick={() => handleRowClick(index)}
                                className={selectedRow === index ? 'selected' : ''}
                                >
                                <TableCell>{machinery.unit}</TableCell>
                                <TableCell>{machinery.model}</TableCell>
                                <TableCell>{machinery.serial_number}</TableCell>
                                <TableCell>{machinery.location}</TableCell>
                                <TableCell>{getClientName(machinery.id_client)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        </Table>
                        <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={filteredMachineries.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                )}
            </div>
            {(isEditing || isCreating) && (
                <div className="edit-popup">
                    <div className="edit-popup-content">
                        <h2>{isCreating ? 'Create Machinery' : 'Edit Machinery'}</h2>
                        {error && <div className="error-message">{error}</div>}
                        <div className="edit-form">
                            <div className="form-group">
                                <label>Unit</label>
                                <input
                                    type="text"
                                    name="unit"
                                    value={editingMachinery.unit}
                                    onChange={handleEditChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Model</label>
                                <input
                                    type="text"
                                    name="model"
                                    value={editingMachinery.model}
                                    onChange={handleEditChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Serial Number</label>
                                <input
                                    type="text"
                                    name="serial_number"
                                    value={editingMachinery.serial_number}
                                    onChange={handleEditChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Location</label>
                                <input
                                    type="text"
                                    name="location"
                                    value={editingMachinery.location}
                                    onChange={handleEditChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Client</label>
                                <select
                                    name="id_client"
                                    value={editingMachinery.id_client}
                                    onChange={handleEditChange}
                                >
                                    <option value="">Select a client</option>
                                    {clients.map(client => (
                                        <option key={client.id} value={client.id}>
                                            {client.client_name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="edit-buttons">
                            <button className="cancel-btn" onClick={() => {
                                setIsEditing(false);
                                setIsCreating(false);
                                setEditingMachinery(null);
                                setError('');
                            }}>Cancel</button>
                            <button className="save-btn" onClick={handleSave}>Save</button>
                        </div>
                    </div>
                </div>
            )}
            {isDeleting && (
                <div className="delete-alert">
                    <div className="delete-alert-content">
                        <h2>⚠ Alert</h2>
                        <p>Do you want to delete {deletingMachinery.unit}?</p>
                        <div className="delete-buttons">
                            <button className="cancel-btn" onClick={() => setIsDeleting(false)}>Cancel</button>
                            <button className="ok-btn" onClick={confirmDelete}>Ok</button>
                        </div>
                    </div>
                </div>
            )}
            {showConfirmation && (
                <div className="confirmation-popup">
                    <div className="confirmation-popup-content">
                        <h2>✓ Done</h2>
                        <p>{confirmationMessage}</p>
                        <button className="ok-btn" onClick={() => setShowConfirmation(false)}>Ok</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MachineryModule;
