import React, { useState } from 'react';
import '../../styles/login_style/ResetAccount.scss';
import { useNavigate } from 'react-router-dom';
import api from '../../utils/api';

function ResetAccount() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const handleGoBack = (e) => {
    e.preventDefault();
    navigate('/');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.post('/password-reset/', { email });
      setIsSubmitted(true);
      setMessage('If an account with this email exists, you will receive a password reset link shortly.');
      setEmail('');
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <div className="reset-container">
      <div className="reset-box">
        {!isSubmitted ? (
          <>
            <h2>Reset Account</h2>
            <p>Enter your email</p>
            <form onSubmit={handleSubmit}>
              <div className="reset-input-group">
                <div className="input-group">
                  <input 
                    type="email" 
                    id="email" 
                    name="email" 
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
              {error && <div className="error-message">{error}</div>}
              <button type="submit" className="reset-button">Reset</button>
            </form>
            <a href="#" onClick={handleGoBack} className="go-back">Go back</a>
          </>
        ) : (
          <>
            <h2>Reset Account</h2>
            <p>{message}</p>
            <button onClick={() => navigate('/')} className="ok-button">Ok</button>
          </>
        )}
      </div>
    </div>
  );
}

export default ResetAccount;
