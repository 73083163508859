import React, { useState, useEffect } from 'react';
import '../../styles/purchase_order_style/PurchaseOrderModule.scss';
import Sidebar from '../../components/Sidebar';
import api from '../../utils/api';
import icon1 from '../../assets/images/icon_delete.svg';
import icon2 from '../../assets/images/icon_edit.svg';
import icon3 from '../../assets/images/icon_add.svg';
import { 
  TextField, 
  IconButton, 
  InputAdornment, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  TablePagination, 
  Paper,
  Box
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PurchaseOrderItems from './PurchaseOrderItems';

const PurchaseOrderModule = () => {
  const [orders, setOrders] = useState([]);
  const [providers, setProviders] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isEditing, setIsEditing] = useState(false);
  const [editingOrder, setEditingOrder] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingOrder, setDeletingOrder] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [showItems, setShowItems] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [ordersResponse, providersResponse, statusesResponse] = await Promise.all([
          api.get('/purchase-orders/'),
          api.get('/providers/'),
          api.get('/status/')
        ]);
        setOrders(ordersResponse.data);
        setFilteredOrders(ordersResponse.data);
        setProviders(providersResponse.data);
        const filteredStatuses = statusesResponse.data.filter(
          status => status.id_status_category === 1
        );
        setStatuses(filteredStatuses);
        setLoading(false);
      } catch (err) {
        setError('Error fetching data');
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const getProviderInfo = (id) => {
    const provider = providers.find(p => p.id === id);
    return provider || { provider_name: 'Unknown', address: 'Unknown' };
  };

  const getStatusName = (id) => {
    const status = statuses.find(s => s.id === id);
    return status ? status.name : 'Unknown';
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    setPage(0);
    const filtered = orders.filter(order => {
      const provider = getProviderInfo(order.id_provider);
      return (
        `ON-${order.id}`.toLowerCase().includes(searchTerm) ||
        provider.provider_name.toLowerCase().includes(searchTerm) ||
        provider.address.toLowerCase().includes(searchTerm) ||
        getStatusName(order.id_status).toLowerCase().includes(searchTerm)
      );
    });
    setFilteredOrders(filtered);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (index, orderId) => {
    if (selectedRow === index) {
      setSelectedOrderId(orderId);
      setShowItems(true);
    }
    setSelectedRow(index === selectedRow ? null : index);
  };

  const handleEdit = () => {
    if (selectedRow !== null) {
      setEditingOrder(filteredOrders[selectedRow]);
      setIsEditing(true);
    }
  };

  const handleCreate = () => {
    setEditingOrder({
      sent_date: new Date().toISOString().split('T')[0],
      id_provider: '',
      id_status: ''
    });
    setIsCreating(true);
  };

  const handleDelete = () => {
    if (selectedRow !== null) {
      setDeletingOrder(filteredOrders[selectedRow]);
      setIsDeleting(true);
    }
  };

  const handleEditChange = (e) => {
    setEditingOrder({ ...editingOrder, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    try {
      if (isCreating) {
        await api.post('/purchase-orders/', editingOrder);
        setConfirmationMessage('Purchase order created successfully');
      } else {
        await api.put(`/purchase-orders/${editingOrder.id}/`, editingOrder);
        setConfirmationMessage('Purchase order updated successfully');
      }
      const response = await api.get('/purchase-orders/');
      setOrders(response.data);
      setFilteredOrders(response.data);
      setIsEditing(false);
      setIsCreating(false);
      setEditingOrder(null);
      setShowConfirmation(true);
    } catch (err) {
      setError('Error saving data');
    }
  };

  const confirmDelete = async () => {
    try {
      await api.post(`/purchase-orders/${deletingOrder.id}/deactivate/`);
      const response = await api.get('/purchase-orders/');
      setOrders(response.data);
      setFilteredOrders(response.data);
      setIsDeleting(false);
      setDeletingOrder(null);
      setSelectedRow(null);
      setConfirmationMessage('Purchase order deleted successfully');
      setShowConfirmation(true);
    } catch (err) {
      setError('Error deleting data');
    }
  };

  return (
    <div className="purchase-order-module-container">
      <Sidebar userType="admin" />
      <div className="purchase-order-module">
        <h1>Purchase Orders</h1>
        <Box className="action-container">
          <TextField
            fullWidth
            placeholder="Search..."
            variant="outlined"
            InputProps={{
              endAdornment: (
                <IconButton className="search-button">
                  <SearchIcon />
                </IconButton>
              ),
            }}
            className="search-input"
            onChange={handleSearch}
          />
          <IconButton className="delete-button" onClick={handleDelete}>
            <img src={icon1} alt="Delete" />
          </IconButton>
          <IconButton className="edit-button" onClick={handleEdit}>
            <img src={icon2} alt="Edit" />
          </IconButton>
          <IconButton className="add-button" onClick={handleCreate}>
            <img src={icon3} alt="Add" />
          </IconButton>
        </Box>
        {loading ? (
          <p>Loading purchase orders...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <TableContainer component={Paper}>
            <Table className="purchase-order-table">
              <TableHead>
                <TableRow>
                  <TableCell>Order Number</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Provider</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredOrders
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((order, index) => {
                    const provider = getProviderInfo(order.id_provider);
                    return (
                      <TableRow 
                        key={order.id} 
                        onClick={() => handleRowClick(index, order.id)}
                        className={selectedRow === index ? 'selected' : ''}
                      >
                        <TableCell>{`ON-${order.id}`}</TableCell>
                        <TableCell>{order.sent_date}</TableCell>
                        <TableCell>{provider.provider_name}</TableCell>
                        <TableCell>{provider.address}</TableCell>
                        <TableCell>{getStatusName(order.id_status)}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredOrders.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
      </div>
      {(isEditing || isCreating) && (
        <div className="edit-popup">
          <div className="edit-popup-content">
            <h2>{isCreating ? 'Create Purchase Order' : 'Edit Purchase Order'}</h2>
            {error && <div className="error-message">{error}</div>}
            <div className="edit-form">
              <div className="form-group">
                <label>Date</label>
                <input
                  type="date"
                  name="sent_date"
                  value={editingOrder.sent_date}
                  onChange={handleEditChange}
                />
              </div>
              <div className="form-group">
                <label>Provider</label>
                <select
                  name="id_provider"
                  value={editingOrder.id_provider}
                  onChange={handleEditChange}
                >
                  <option value="">Select Provider</option>
                  {providers.map(provider => (
                    <option key={provider.id} value={provider.id}>
                      {provider.provider_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>Status</label>
                <select
                  name="id_status"
                  value={editingOrder.id_status}
                  onChange={handleEditChange}
                >
                  <option value="">Select Status</option>
                  {statuses.map(status => (
                    <option key={status.id} value={status.id}>
                      {status.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="edit-buttons">
              <button className="cancel-btn" onClick={() => {
                setIsEditing(false);
                setIsCreating(false);
                setEditingOrder(null);
                setError('');
              }}>Cancel</button>
              <button className="save-btn" onClick={handleSave}>Save</button>
            </div>
          </div>
        </div>
      )}

      {isDeleting && (
        <div className="delete-alert">
          <div className="delete-alert-content">
            <h2>⚠ Alert</h2>
            <p>Do you want to delete Order #{deletingOrder.id}?</p>
            <div className="delete-buttons">
              <button className="cancel-btn" onClick={() => setIsDeleting(false)}>Cancel</button>
              <button className="ok-btn" onClick={confirmDelete}>Ok</button>
            </div>
          </div>
        </div>
      )}

      {showConfirmation && (
        <div className="confirmation-popup">
          <div className="confirmation-popup-content">
            <h2>✓ Done</h2>
            <p>{confirmationMessage}</p>
            <button className="ok-btn" onClick={() => setShowConfirmation(false)}>Ok</button>
          </div>
        </div>
      )}

      {showItems && (
        <PurchaseOrderItems 
          orderId={selectedOrderId}
          onClose={() => setShowItems(false)}
        />
      )}
    </div>
  );
};

export default PurchaseOrderModule;
