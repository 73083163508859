import React, { useState, useEffect } from 'react';
import '../../styles/inventory_style/InventoryModule.scss';
import Sidebar from '../../components/Sidebar';
import api from '../../utils/api';
import icon1 from '../../assets/images/icon_delete.svg';
import icon2 from '../../assets/images/icon_edit.svg';
import icon3 from '../../assets/images/icon_add.svg';
import { 
  TextField, 
  IconButton, 
  InputAdornment, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  TablePagination, 
  Paper,
  Box
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const InventoryModule = () => {
  const [items, setItems] = useState([]);
  const [providers, setProviders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingItem, setDeletingItem] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const rowsPerPage = 7;
  const [page, setPage] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [itemsResponse, providersResponse] = await Promise.all([
          api.get('/items/'),
          api.get('/providers/')
        ]);
        setItems(itemsResponse.data);
        setFilteredItems(itemsResponse.data);
        setProviders(providersResponse.data);
        setLoading(false);
      } catch (err) {
        setError('Error fetching data');
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleRowClick = (index) => {
    setSelectedRow(index === selectedRow ? null : index);
  };

  const handleEdit = async () => {
    if (selectedRow !== null) {
      try {
        const response = await api.get(`/items/${items[selectedRow].id}/`);
        setEditingItem(response.data);
        setIsEditing(true);
      } catch (error) {
        console.error('Error fetching item details:', error);
      }
    }
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditingItem({ ...editingItem, [name]: value });
  };

  const handleSave = async () => {
    if (editingItem) {
      try {
        let response;
        if (isCreating) {
          response = await api.post('/items/', editingItem);
        } else {
          response = await api.put(`/items/${editingItem.id}/`, editingItem);
        }
        const updatedItem = response.data;
        
        const itemsResponse = await api.get('/items/');
        setItems(itemsResponse.data);
        setFilteredItems(itemsResponse.data);

        setIsEditing(false);
        setIsCreating(false);
        setEditingItem(null);
        setError('');

        setConfirmationMessage(`Item ${updatedItem.item_name} ${isCreating ? 'created' : 'edited'}.`);
        setShowConfirmation(true);
      } catch (error) {
        console.error('Error saving item:', error);
        setError('An error occurred while saving the item. Please try again.');
      }
    }
  };

  const handleDelete = () => {
    if (selectedRow !== null) {
      setDeletingItem(items[selectedRow]);
      setIsDeleting(true);
    }
  };

  const confirmDelete = async () => {
    if (deletingItem) {
      try {
        await api.post(`/items/${deletingItem.id}/deactivate/`);
        
        const itemsResponse = await api.get('/items/');
        setItems(itemsResponse.data);
        setFilteredItems(itemsResponse.data);

        setIsDeleting(false);
        setDeletingItem(null);
        setSelectedRow(null);
        setShowConfirmation(true);
        setConfirmationMessage(`Item ${deletingItem.item_name} deleted.`);
      } catch (error) {
        console.error('Error deleting item:', error);
      }
    }
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };

  const handleCreate = () => {
    setEditingItem({
      part_id: '',
      item_name: '',
      quantity: 0,
      price: '',
      id_provider: '',
    });
    setIsCreating(true);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = items.filter(item => 
      Object.values(item).some(value => 
        value.toString().toLowerCase().includes(term)
      )
    );

    setFilteredItems(filtered);
  };

  const getProviderName = (id) => {
    const provider = providers.find(p => p.id === id);
    return provider ? provider.provider_name : 'Unknown';
  };

  const paginatedItems = filteredItems.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <div className="inventory-module-container">
      <Sidebar userType="admin" />
      <div className="inventory-module">
        <h1>Inventory Module</h1>
        <Box className="action-container">
          <TextField
            fullWidth
            placeholder="Search..."
            variant="outlined"
            InputProps={{
              endAdornment: (
                <IconButton className="search-button">
                  <SearchIcon />
                </IconButton>
              ),
            }}
            className="search-input"
            onChange={handleSearch}
          />
          <IconButton className="delete-button" onClick={handleDelete}>
            <img src={icon1} alt="Delete" />
          </IconButton>
          <IconButton className="edit-button" onClick={handleEdit}>
            <img src={icon2} alt="Edit" />
          </IconButton>
          <IconButton className="add-button" onClick={handleCreate}>
            <img src={icon3} alt="Add" />
          </IconButton>
        </Box>
        {loading ? (
          <p>Loading inventory...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <TableContainer component={Paper}>
            <Table className="inventory-table">
              <TableHead>
                <TableRow>
                  <TableCell>Part ID</TableCell>
                  <TableCell>Item Name</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Provider</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedItems.map((item, index) => (
                    <TableRow 
                      key={item.id} 
                      onClick={() => handleRowClick(index)}
                      className={selectedRow === index ? 'selected' : ''}
                    >
                      <TableCell>{item.part_id}</TableCell>
                      <TableCell>{item.item_name}</TableCell>
                      <TableCell>{item.quantity}</TableCell>
                      <TableCell>{getProviderName(item.id_provider)}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={filteredItems.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[7]}
            />
          </TableContainer>
        )}
      </div>
      {(isEditing || isCreating) && (
        <div className="edit-popup">
          <div className="edit-popup-content">
            <h2>{isCreating ? 'Create Item' : 'Edit Item'}</h2>
            {error && <div className="error-message">{error}</div>}
            <div className="edit-form">
              <div className="form-group">
                <label>Part ID</label>
                <input
                  type="text"
                  name="part_id"
                  value={editingItem.part_id}
                  onChange={handleEditChange}
                />
              </div>
              <div className="form-group">
                <label>Item Name</label>
                <input
                  type="text"
                  name="item_name"
                  value={editingItem.item_name}
                  onChange={handleEditChange}
                />
              </div>
              <div className="form-group">
                <label>Quantity</label>
                <input
                  type="number"
                  name="quantity"
                  value={editingItem.quantity}
                  onChange={handleEditChange}
                />
              </div>
              <div className="form-group">
                <label>Provider</label>
                <select
                  name="id_provider"
                  value={editingItem.id_provider}
                  onChange={handleEditChange}
                >
                  <option value="">Select a provider</option>
                  {providers.map(provider => (
                    <option key={provider.id} value={provider.id}>
                      {provider.provider_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>Price</label>
                <div className="price-input-wrapper">
                  <span className="dollar-sign" style={{ paddingBottom: '3px' }}>$</span>
                  <input
                    className="price-input"
                    type="number"
                    step="0.01"
                    name="price"
                    value={editingItem.price}
                    onChange={handleEditChange}
                    style={{ paddingLeft: '20px' }}
                  />
                </div>
              </div>
            </div>
            <div className="edit-buttons">
              <button className="cancel-btn" onClick={() => {
                setIsEditing(false);
                setIsCreating(false);
                setEditingItem(null);
                setError('');
              }}>Cancel</button>
              <button className="save-btn" onClick={handleSave}>Save</button>
            </div>
          </div>
        </div>
      )}
      {isDeleting && (
        <div className="delete-alert">
          <div className="delete-alert-content">
            <h2>⚠ Alert</h2>
            <p>Do you want to delete {deletingItem.item_name}?</p>
            <div className="delete-buttons">
              <button className="cancel-btn" onClick={() => setIsDeleting(false)}>Cancel</button>
              <button className="ok-btn" onClick={confirmDelete}>Ok</button>
            </div>
          </div>
        </div>
      )}
      {showConfirmation && (
        <div className="confirmation-popup">
          <div className="confirmation-popup-content">
            <h2>✓ Done</h2>
            <p>{confirmationMessage}</p>
            <button className="ok-btn" onClick={handleConfirmationClose}>Ok</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default InventoryModule;
