import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../utils/api';
import '../../styles/login_style/ResetPasswordForm.scss';

const ResetPasswordForm = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false
  });
  const { uid, token } = useParams();
  const navigate = useNavigate();

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    updatePasswordRequirements(value);
  };

  const updatePasswordRequirements = (password) => {
    setPasswordRequirements({
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password)
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    if (!Object.values(passwordRequirements).every(Boolean)) {
      setError('Password does not meet all requirements');
      return;
    }
    try {
      await api.post(`/password-reset-confirm/`, { new_password: password, uid, token });
      navigate('/', { state: { message: 'Password reset successful. You can now log in with your new password.' } });
    } catch (error) {
      setError('Failed to reset password. Please try again.');
    }
  };

  return (
    <div className="reset-password-container">
      <div className="reset-password-form">
        <h2>Reset Your Password</h2>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="password">New Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm New Password</label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <div className="password-requirements">
            <p>Password must contain:</p>
            <ul>
              <li className={passwordRequirements.length ? 'met' : ''}>At least 8 characters</li>
              <li className={passwordRequirements.uppercase ? 'met' : ''}>1 uppercase letter</li>
              <li className={passwordRequirements.lowercase ? 'met' : ''}>1 lowercase letter</li>
              <li className={passwordRequirements.number ? 'met' : ''}>1 number</li>
              <li className={passwordRequirements.specialChar ? 'met' : ''}>1 special character</li>
            </ul>
          </div>
          <button type="submit">Reset Password</button>
        </form>
      </div>
    </div>
)};

export default ResetPasswordForm;
